import React, { useEffect, useState } from 'react';
import { Box, Panel, Flex, FlexItem, Tabs } from '@bigcommerce/big-design';
import Products from '../components/faq-writer/Products';
import FAQSettings from '../components/faq-writer/FAQSettings';
import ProductsWithFAQ from '../components/faq-writer/ProductsWithFAQ';
import { StoreClient } from '../services/StoreClient';

const FAQWriter = (props) => {
    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState();
    const [activeTab, setActiveTab] = useState("tab-all-products");

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const store = await StoreClient.getStore();
                setStore(store);
            }
            catch {
                // setError("Unable to authenticate BigCommerce store");
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {store &&
                <>
                    <Flex>
                        <FlexItem flexOrder={2} flexGrow={1} flexShrink={4} margin="small">
                            <FAQSettings store={store}></FAQSettings>
                        </FlexItem>
                        <FlexItem flexOrder={1} flexGrow={4} flexShrink={1} margin="small">
                            <Panel>
                                <Tabs
                                    activeTab={activeTab}
                                    aria-label="Product Tabs"
                                    id="tab"
                                    items={[
                                        { id: "tab-all-products", ariaControls: "all-products", title: "All Products" },
                                        { id: "tab-products-with-faq", ariaControls: "products-with-faq", title: "Products with FAQs" }
                                    ]}
                                    onTabClick={setActiveTab}
                                />
                                <Box marginTop="medium" border="none" backgroundColor="transparent"></Box>
                                {activeTab === "tab-all-products" &&
                                    <div id="tab-all-products">
                                        <Products store={store}></Products>
                                    </div>
                                }
                                {activeTab === "tab-products-with-faq" &&
                                    <div id="tab-products-with-faq">
                                        <ProductsWithFAQ></ProductsWithFAQ>
                                    </div>
                                }
                            </Panel>
                        </FlexItem>
                    </Flex>
                </>
            }
        </>
    );
}

export default FAQWriter;
