import React, { useEffect, useState } from 'react';
import { Box, Panel, Flex, FlexItem, Tabs } from '@bigcommerce/big-design';
import { StoreClient } from '../services/StoreClient';
import BlogPosts from '../components/blog-writer/BlogPosts';
import BlogSettings from '../components/blog-writer/BlogSettings';

const BlogWriter = (props) => {
    const [loading, setLoading] = useState(true);
    const [store, setStore] = useState();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const store = await StoreClient.getStore();
                setStore(store);
            }
            catch {
                // setError("Unable to authenticate BigCommerce store");
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {store &&
                <>
                    <Flex>
                        <FlexItem flexOrder={2} flexGrow={1} flexShrink={4} margin="small">
                            <BlogSettings store={store}></BlogSettings>
                        </FlexItem>
                        <FlexItem flexOrder={1} flexGrow={4} flexShrink={1} margin="small">
                            <Panel>
                                <Tabs
                                    activeTab="tab-ai-generated-blog-posts"
                                    aria-label="Blog Tab Content"
                                    id="tab"
                                    items={[
                                        { id: "tab-ai-generated-blog-posts", ariaControls: "ai-generated-blog-posts", title: "AI-Generated Blog Posts" },
                                    ]}
                                />
                                <Box marginTop="medium" border="none" backgroundColor="transparent"></Box>
                                <BlogPosts></BlogPosts>
                            </Panel>
                        </FlexItem>
                    </Flex>
                </>
            }
        </>
    );
}

export default BlogWriter;
