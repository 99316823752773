import { Badge, Box, Button, Checkbox, Flex, FlexItem, Form, Link, Modal, Small, Text } from "@bigcommerce/big-design";
import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../services/Api";
import { SharedState } from "../../services/SharedState";
import ComponentLoading from "../ComponentLoading";
import MemoizedTable from "../MemoizedTable";
import FAQDetail from "./FAQDetail";
import "./Products.css";
import ProductSearchForm from "../description-writer/product/ProductSearchForm";
import SafeImg from "../SafeImg";
import { StoreClient } from "../../services/StoreClient";

const Products = (props) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [items, setItems] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemsTimestamp, setItemsTimestamp] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [categories, setCategories] = useState(SharedState.getValue("categories"));
    const [selectedProduct, setSelectedProduct] = useState(null);

    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchCategories, setSearchCategories] = useState([]);
    const [searchBrand, setSearchBrand] = useState([]);
    const [searchTimestamp, setSearchTimestamp] = useState(0);
    const [creationDateStart, setCreationDateStart] = useState();
    const [creationDateEnd, setCreationDateEnd] = useState();
    const [modificationDateStart, setModificationDateStart] = useState();
    const [modificationDateEnd, setModificationDateEnd] = useState();
    const [onlyWithoutFAQ, setOnlyWithoutFAQ] = useState(false);
    const [onlyVisible, setOnlyVisible] = useState(false);
    const [onlyInStock, setOnlyInStock] = useState(false);
    const [sortBy, setSortBy] = useState();
    const [sortDirection, setSortDirection] = useState();

    const [isBatchGenerateModalOpen, setIsBatchGenerateModalOpen] = useState(false);
    const [isGeneratingBatchFAQ, setIsGeneratingBatchFAQ] = useState(false);

    const productDetailModal = useRef();

    const getData = async (page) => {
        try {
            setItems([]);
            setItemsTimestamp(0);
            setLoading(true);
            const listItems = [];
            const url = !onlyWithoutFAQ ? "/faqApi-getProducts" : "/faqApi-getProductsWithoutFAQ"
            const data = await Api.get(url, {
                keyword: searchKeyword,
                categories: searchCategories.join(","),
                brand: searchBrand,
                creationDateStart: creationDateStart,
                creationDateEnd: creationDateEnd,
                modificationDateStart: modificationDateStart,
                modificationDateEnd: modificationDateEnd,
                onlyVisible: onlyVisible,
                onlyInStock: onlyInStock,
                sortDirection: sortDirection,
                sortBy: sortBy,
                page: page,
                pageSize: pageSize
            });
            data.products.forEach(p => {
                listItems.push({
                    ...p,
                    checked: false
                });
            });

            setItems(listItems);
            setItemsTimestamp(new Date().getTime());
            setTotalItems(data.total);
            setCurrentPage(page);
        }
        finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getData(1);
        SharedState.subscribe("categories", (key, value) => {
            if (key === "categories") {
                setCategories(value);
            }
        });
    }, [searchCategories, searchBrand, searchKeyword, searchTimestamp, pageSize]);

    const onSearch = (keyword, categories, brand, creationDateStart, creationDateEnd, modificationDateStart, modificationDateEnd, onlyWithoutFAQ, onlyVisible, onlyInStock, sortBy, sortDirection) => {
        setSearchKeyword(keyword);
        setSearchCategories(categories);
        setSearchBrand(brand);
        setCreationDateStart(creationDateStart);
        setCreationDateEnd(creationDateEnd);
        setModificationDateStart(modificationDateStart);
        setModificationDateEnd(modificationDateEnd);
        setOnlyWithoutFAQ(onlyWithoutFAQ);
        setOnlyVisible(onlyVisible);
        setOnlyInStock(onlyInStock);
        setSortBy(sortBy);
        setSortDirection(sortDirection);
        setSearchTimestamp(new Date().getTime());
    };

    const renderProductImage = (product) => {
        try {
            const thumb = product.images.find(i => i.is_thumbnail === true);
            return <SafeImg className="thumbnail" src={thumb.url_tiny} />
        }
        catch {
            return <img src=""></img>
        }
    }

    const renderName = (product) => {
        const productCategories = categories ? categories.filter(c => product.categories.indexOf(c.id) >= 0).map(c => c.name) : [];
        return (
            <>
                <div className="product-name">
                    <Link ellipsis={true} onClick={() => setSelectedProduct(product)}>{product.name}</Link>
                </div>
                <div className="product-name">
                    <Small ellipsis={true}>{productCategories.join('/')}</Small>
                </div>
                <div className="product-name">
                    <Small ellipsis={true}>{product.sku}</Small>
                </div>
            </>
        )
    }

    useEffect(() => {
        if (selectedProduct !== null) {
            const productDetailElement = document.querySelector(".faq-detail");
            productDetailElement.parentElement.parentElement.style.maxWidth = "85%";
        }
    }, [selectedProduct])

    const renderStatus = (product) => {
        let variant = "secondary";
        let status = "not-generated";
        const faqData = product.faq;
        if (faqData) {
            if (faqData.status === "published") {
                variant = "success";
            }
            else if (faqData.status === "not-published") {
                variant = "secondary";
            }
            else if (faqData.status === "outdated") {
                variant = "warning";
            }
            status = faqData.status;
        }

        return (
            <>
                <Badge label={status} variant={variant}></Badge>
            </>
        );
    }

    const batchGenerateFAQs = async () => {
        setIsGeneratingBatchFAQ(true);
        try {
            const ids = selectedItems.map((i) => {
                return i.id;
            });
            await Api.post("/aiApi-batchGenerateFAQs", {
                productIds: ids,
            });
            setIsBatchGenerateModalOpen(false);
            StoreClient.getStore();
        }
        finally {
            setIsGeneratingBatchFAQ(false);
        }
    }

    const pagingEnabled = () => {
        if (onlyWithoutFAQ) {
            return false;
        }

        return true;
    }

    return (
        <>
            <div className="products">
                <Box marginBottom="medium">
                    <ProductSearchForm mode="FAQ" sort={true} filterByDate={true} filterNonAiModified={props.store.subscription && props.store.subscription.planId !== "FREE" && props.store.subscription.status === "active"} onSubmit={onSearch}></ProductSearchForm>
                </Box>
                {onlyWithoutFAQ &&
                    <Flex marginBottom="medium" justifyContent="end">
                        <FlexItem>
                            <small>No-FAQ search result is limited to 250 records</small>
                        </FlexItem>
                    </Flex>
                }
                <Flex marginBottom="medium" justifyContent="end">
                    <FlexItem>
                        <Button onClick={() => setIsBatchGenerateModalOpen(true)} disabled={selectedItems.length === 0}>Batch Generate Content</Button>
                    </FlexItem>
                </Flex>
                {itemsTimestamp &&
                    <MemoizedTable
                        columns={[
                            { header: '', hash: 'thumbnail', width: "100px", render: (product) => renderProductImage(product) },
                            { header: 'Name', hash: 'name', width: "500px", render: (product) => renderName(product) },
                            { header: 'Q&A', width: "100px", hash: 'faq', render: (product) => renderStatus(product) },
                        ]}
                        onSelectionChange={(e) => {
                            setSelectedItems(e);
                        }}
                        items={items}
                        timestamp={itemsTimestamp}
                        pagination={pagingEnabled() && {
                            itemsPerPageOptions: [20, 50, 100],
                            currentPage: currentPage,
                            itemsPerPage: pageSize,
                            totalItems: totalItems,
                            onPageChange: (page) => getData(page),
                            onItemsPerPageChange: (range) => { setPageSize(range); },
                            getRangeLabel: (start, end, totalItems) => { return pageSize }

                        }}
                        stickyHeader
                        loading={loading}
                    />
                }
                {loading &&
                    <ComponentLoading></ComponentLoading>
                }
            </div>
            <Modal
                isOpen={selectedProduct}
                onClose={() => setSelectedProduct(null)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
            >
                <FAQDetail product={selectedProduct}></FAQDetail>
            </Modal>
            <Modal
                isOpen={isBatchGenerateModalOpen}
                onClose={() => setIsBatchGenerateModalOpen(false)}
                closeOnEscKey={true}
                closeOnClickOutside={true}
                header="Batch Generate Content"
                actions={[
                    {
                        text: 'Cancel',
                        variant: 'subtle',
                        onClick: () => setIsBatchGenerateModalOpen(false),
                    },
                    {
                        isLoading: isGeneratingBatchFAQ,
                        text: 'Apply',
                        onClick: batchGenerateFAQs
                    },
                ]}
            >
                <Text>You selected <strong>{selectedItems.length} products</strong>.</Text>
                <Text>AI will generate and publish FAQs for these products.</Text>
            </Modal>
        </>
    );
}
export default Products;
