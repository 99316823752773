import React, { useEffect, useState } from 'react';
import { Box, Panel, Flex, FlexItem, Tabs } from '@bigcommerce/big-design';
import AIModifiedCategories from '../components/description-writer/category/AIModifiedCategories';
import Categories from '../components/description-writer/category/Categories';
import CategorySettings from '../components/description-writer/category/CategorySettings';
import { StoreClient } from '../services/StoreClient';

const CategoryWriter = (props) => {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("tab-all-categories");
    const [store, setStore] = useState();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const store = await StoreClient.getStore();
                setStore(store);
            }
            catch {
                // setError("Unable to authenticate BigCommerce store");
            }
            finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    return (
        <>
            {store &&
                <>
                    <Flex>
                        <FlexItem flexOrder={2} flexGrow={1} flexShrink={4} margin="small">
                            <CategorySettings store={store}></CategorySettings>
                        </FlexItem>
                        <FlexItem flexOrder={1} flexGrow={4} flexShrink={1} margin="small">
                            <Panel>
                                <Tabs
                                    activeTab={activeTab}
                                    aria-label="Product Tab Content"
                                    id="tab"
                                    items={[
                                        { id: "tab-all-categories", ariaControls: "all-categories", title: "All Categories" },
                                        { id: "tab-ai-modified-categories", ariaControls: "ai-modified-categories", title: "AI-Modified Categories" },
                                    ]}
                                    onTabClick={setActiveTab}
                                />
                                <Box marginTop="medium" border="none" backgroundColor="transparent"></Box>
                                {activeTab === "tab-all-categories" &&
                                    <div id="tab-all-categories">
                                        <Categories></Categories>
                                    </div>
                                }
                                {activeTab === "tab-ai-modified-categories" &&
                                    <div id="tab-ai-categories">
                                        <AIModifiedCategories></AIModifiedCategories>
                                    </div>
                                }
                            </Panel>
                        </FlexItem>
                    </Flex>
                </>
            }
        </>
    );
}

export default CategoryWriter;
